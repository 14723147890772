import React from 'react';
import { useSiteMetadata } from '../../components/SiteData'
import AppLayout from "../../components/AppLayout";
import FeatureDisplay, { FeatureDisplayImage, FeatureText } from '../../components/FeatureDisplay'
import FeatureDetailPage from '../../components/FeatureDetailPage';
import PageInfo from '../../components/PageInfo'
import FeatureNav from '../../components/FeatureNav'
import { graphql } from 'gatsby'
import Brand from '../../components/shared/Brand'
import RouterLink from '../../components/shared/RouterLink'

export default function Reporting(props) {
	let {data} = props;
	const {productName} = useSiteMetadata();

	return (
		<AppLayout>
			<FeatureNav/>
			<FeatureDetailPage
				title="Time and Expense Reports"
				description={(
					<p>
						The report and dashboard capabilities of <Brand/> allows you to view graphs and inspect your team information using search filters and custom columns.
						Once you have the report configured just right, you can export time logs and expenses for your team.
					</p>
				)}
			>
				<PageInfo
					title={"Run Reports with " + productName}
					description={"Run timekeeping reports with " + productName + " time tracker. Choose custom search filters and columns, and optionally export the results."}
				/>

				<section className="tt-limit-content-width">

					<FeatureDisplay
						header="Dashboard Visualization"
						imgPlacement="right"
						img={<FeatureDisplayImage imgData={data.dashboardImg.childImageSharp.gatsbyImageData} alt="Dashboard"/>}
					>
						<FeatureText
							description={(<>
								<p>Look at a graphical representation of your time logs and expenses.</p>
								<p>Check out what your team is doing at a glance. Or compare your time and expenses with historical data. A picture is worth a thousand words.</p>
							</>)}
							small
						/>
					</FeatureDisplay>

					<FeatureDisplay
						header="View Your Team's Time Logs and Expenses"
						odd
						imgPlacement="left"
						img={<FeatureDisplayImage imgData={data.reportOverallImg.childImageSharp.gatsbyImageData} alt="Time Log Report"/>}
					>
						<FeatureText
							description={(<>
								<p>Get a listing of your team's expenses as well as time logs and the number of hours worked.</p>
								<p>Tap the column headers to sort the results.</p>
								<p>If you only care about the totals, use the handy "View Totals Only" button.</p>
							</>)}
							small
						/>
					</FeatureDisplay>

					<FeatureDisplay
						header="Filter by Date, Project, User, or Billable Status"
						imgPlacement="right"
						img={<FeatureDisplayImage imgData={data.projectFilterImg.childImageSharp.gatsbyImageData} alt="Report Filters"/>}
					>
						<FeatureText
							description={(<>
								<p>Use the date filter to pick from a calendar, or type in a date just like when you edit time logs or expenses.</p>
								<p>Also filter by projects, users, and/or billable status.</p>
							</>)}
							small
						/>
					</FeatureDisplay>

					<FeatureDisplay
						header="Choose Columns and Export Results"
						odd
						imgPlacement="left"
						img={<FeatureDisplayImage imgData={data.columnsAndExportImg.childImageSharp.gatsbyImageData} alt="Report Options with Columns"/>}
					>
						<FeatureText
							description={(<>
								<p>Customize the columns in your report.  Optionally include hours rounded in 6 or 15 minute increments.</p>
								<p>Export to Excel or CSV using the filter and column selections you've made.</p>
								<p>
									All of these columns are also available when you want to
									{' '}<RouterLink to="/blog/invoice-spreadsheet-attachments/">customize spreadsheet attachments</RouterLink>
									{' '}for invoice emails that you send your clients.
								</p>
							</>)}
							small
						/>
					</FeatureDisplay>

				</section>

			</FeatureDetailPage>
		</AppLayout>
	);
}
export const query = graphql`{
  columnsAndExportImg: file(relativePath: {eq: "images/features/run-reports/columns-and-export.png"}) {
    childImageSharp { gatsbyImageData(width: 474, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  dashboardImg: file(relativePath: {eq: "images/features/run-reports/dashboard.png"}) {
    childImageSharp { gatsbyImageData(width: 962, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  projectFilterImg: file(relativePath: {eq: "images/features/run-reports/project-filter.png"}) {
    childImageSharp { gatsbyImageData(width: 408, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  reportOverallImg: file(relativePath: {eq: "images/features/run-reports/report-overall.png"}) {
    childImageSharp { gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH) }
  }
}
`;
